
<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.cooperation')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <div class="banner">
            <b-container class="centrr">
                <div class="trg">
                    <div class="title">{{$t('message.cooperation')}}</div>
                    <div class="txt">{{$t('message.cooperationtxt1')}}</div>
                    <div class="tip">{{$t('message.cooperationtxt2')}}</div>
                </div>
            </b-container>
        </div>
        <!-- pc布局【合作表单】 -->
        <b-container class="pc-from-box">
            <div class="pc-from-wrap">
                <div class="pc-input-two">
                    <input type="text" :placeholder="'*'+$t('message.allname')" v-model="name"/>
                    <div class="pc-t"></div>
                    <input type="text" :placeholder="'*'+$t('message.age')" v-model="age"/>
                </div>
                <div class="pc-input-two">
                    <input type="text" :placeholder="'*'+$t('message.occupation')" v-model="occupation"/>
                    <div class="pc-t"></div>
                    <input type="text" :placeholder="'*'+$t('message.industry')" v-model="industry"/>
                </div>
                <div class="pc-input"><input type="text" :placeholder="'*'+$t('message.webint')" v-model="website" /></div>
                <div class="pc-input-two">
                    <input type="text" :placeholder="'*'+$t('message.city')" v-model="city"/>
                    <div class="pc-t"></div>
                    <input type="text" :placeholder="'*'+$t('message.status')" v-model="country_state"/>
                </div>
                <div class="pc-input-two">
                    <input type="text" :placeholder="'*'+$t('message.country')" v-model="country"/>
                </div>
                <div class="pc-title">*{{$t('message.firstarr1')}}</div>
                <div class="pc-box">
                    <b-row>
                        <b-col v-for="(item,index) in firstlist" :key="index">
                            <span class="icon_checkbox" :class="{'checked':item.check}" @click="getCheck('firstlist',item,index)"></span>{{item.name}}
                        </b-col>
                    </b-row>
                </div>
                <div class="pc-title">*{{$t('message.firstarr2')}}</div>
                <div class="pc-box">
                    <el-row>
                        <el-col :span="8" v-for="(item,index) in typelist" :key="index">
                            <span class="icon_checkbox" :class="{'checked':item.check}" @click="getCheck('typelist',item,index)"></span>{{item.name}}
                        </el-col>
                    </el-row>
                </div>
                <div class="pc-input-two">
                    <input type="text" :placeholder="$t('message.firstarr3')" v-model="enter_type_str"/>
                </div>
                <div class="pc-input"><input type="text" :placeholder="'*'+$t('message.firstarr4')" v-model="afiliated_company_website" /></div>
                <div class="pc-title">*{{$t('message.firstarr5')}}</div>
                <div class="pc-box">
                    <b-row>
                        <b-col v-for="(i,index) in shoplist" :key="index" @click="getRadio(i,'shoplist')">
                            <span class="icon_radio" :class="{'radioed':i.check}"></span>{{i.name}}
                        </b-col>
                    </b-row>
                </div>
                <div class="pc-title">*{{$t('message.firstarr6')}}</div>
                <div class="pc-box">
                    <el-row>
                        <el-col :span="12" v-for="(val,index) in arealist" :key="index">
                        <span class="icon_radio" @click="getRadio(val,'arealist')" :class="{'radioed':val.check}"></span>{{val.name}}
                        </el-col>
                    </el-row>
                </div>
                <div class="pc-login-group">
                    <select name="" id="" v-model="couponSelected">
                        <option :value="item.mobile_area_code" v-for="(item,index) in languageList" :key="index">{{item.mobile_area_code}}+</option>
                    </select>
                    <input type="text" class="pc-password" :placeholder="'*'+$t('message.movephone')" v-model="telno"/>
                </div>
                <div class="pc-input"><input type="text" :placeholder="'*'+$t('message.email')" v-model="email" /></div>
                <div class="pc-input-two">
                    <!-- <div class="select">
                        <select>
                            <option value="联系方式">联系方式</option>
                        </select>
                        <span class="el-icon-arrow-down"></span>
                    </div> -->
                    <input type="text" :placeholder="$t('message.linktype')" v-model="contact"/>
                    <div class="pc-t"></div>
                    <input type="text" :placeholder="$t('message.phoneinfo')" v-model="contact_info"/>
                </div>
                <div class="pc-title">*{{$t('message.coeatxt1')}}</div>
                <div class="pc-box">
                    <el-row>
                        <el-col :span="12" v-for="(k,index) in tzlist" :key="index">
                            <span class="icon_radio" @click="getRadio(k,'tzlist')" :class="{'radioed':k.check}"></span>{{k.name}}
                        </el-col>
                    </el-row>
                </div>
                <div class="pc-input"><input type="text" :placeholder="$t('message.wherecity')" v-model="intend_shop_city" /></div>
                <div class="pc-input">
                    <input type="text" :placeholder="$t('message.wherewhy')" v-model="working_reason"/>
                </div>
                <div class="pc-input"><input type="text" :placeholder="$t('message.wherename')" v-model="recommending_officer"/></div>
                <div class="pc-box-txt" @click="checked=!checked">
                    <span class="icon_checkbox" :class="{'checked':checked}"></span>
                    <div class="test">{{$t('message.wherecheck1')}}</div>
                </div>
                <div class="pc-box-txt" @click="checked1=!checked1">
                    <span class="icon_checkbox" :class="{'checked':checked1}"></span>
                    <div class="test">{{$t('message.wherecheck2')}}</div>
                </div>
                <div class="clear"></div>
                <div class="pc-link">
                    <span class="pc-linkbtn" @click="getSubmit">{{$t('message.submitbtn')}}</span>
                </div>
            </div>
        </b-container>
        <!-- wap布局【合作表单】 -->
        <b-container class="wap-from-box">
            <div class="wap-from-wrap">
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.allname')" v-model="name" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.age')" v-model="age"/>
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.occupation')" v-model="occupation" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.industry')" v-model="industry" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.webint')" v-model="website" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.city')" v-model="city" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.status')" v-model="country_state" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.country')" v-model="country" />
                </div>
                <div class="wap-label-title">*{{$t('message.firstarr1')}}</div>
                <div class="wap-label-list">
                    <el-row>
                        <el-col :span="12" v-for="(item,index) in firstlist" :key="index">
                            <span class="icon_checkbox" :class="{'checked':item.check}" @click="getCheck('firstlist',item,index)"></span>{{item.name}}
                        </el-col>
                    </el-row>
                </div>
                <div class="wap-label-title">*{{$t('message.firstarr2')}}</div>
                <div class="wap-label-list">
                    <el-row>
                        <el-col :span="12" v-for="(item,index) in typelist" :key="index">
                            <span class="icon_checkbox" :class="{'checked':item.check}" @click="getCheck('typelist',item,index)"></span>{{item.name}}
                        </el-col>
                    </el-row>
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="$t('message.firstarr3')" v-model="enter_type_str" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="'*'+$t('message.firstarr4')" v-model="afiliated_company_website" />
                </div>
                <div class="wap-label-title">*{{$t('message.firstarr5')}}</div>
                <div class="wap-label-list">
                    <el-row>
                        <el-col :span="12" v-for="(item,key) in shoplist" :key="key">
                            <span class="icon_radio"  @click="getRadio(item,'shoplist')" :class="{'radioed':item.check}"></span>{{item.name}}
                        </el-col>
                    </el-row>
                </div>
                <div class="wap-label-title">*{{$t('message.firstarr6')}}</div>
                <div class="wap-label-list">
                    <el-row>
                        <el-col :span="12" v-for="(item,index) in arealist" :key="index">
                            <span class="icon_radio" @click="getRadio(item,'arealist')" :class="{'radioed':item.check}"></span><i v-show="index==0">&lt;</i>{{item.name}}
                        </el-col>
                    </el-row>
                </div>
                <div class="wap-login-group">
                    <select name="" id="" v-model="couponSelected">
                        <option :value="item.mobile_area_code" v-for="(item,index) in languageList" :key="index">{{item.mobile_area_code}}+</option>
                    </select>
                    <input type="text" :placeholder="'*'+$t('message.movephone')" v-model="telno"/>
                </div>
                <div class="wap-input"><input type="text" :placeholder="'*'+$t('message.email')" v-model="email" /></div>
                <div class="wap-input">
                    <input type="text" :placeholder="$t('message.linktype')" v-model="contact" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="$t('message.phoneinfo')" v-model="contact_info" />
                </div>
                <div class="wap-label-title">*{{$t('message.coeatxt1')}}</div>
                <div class="wap-label-list">
                    <el-row>
                        <el-col :span="12" v-for="(item,index) in tzlist" :key="index">
                        <span class="icon_radio" @click="getRadio(item,'tzlist')" :class="{'radioed':item.check}"></span>{{item.name}}
                        </el-col>
                    </el-row>
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="$t('message.wherecity')" v-model="intend_shop_city" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="$t('message.wherewhy')" v-model="working_reason" />
                </div>
                <div class="wap-input">
                    <input type="text" :placeholder="$t('message.wherename')" v-model="recommending_officer" />
                </div>
                <div class="wap-box-txt" @click="checked=!checked">
                    <span class="icon_checkbox" :class="{'checked':checked}"></span>
                    <div class="test">{{$t('message.wherecheck1')}}</div>
                </div>
                <div class="wap-box-txt" @click="checked1=!checked1">
                    <span class="icon_checkbox" :class="{'checked':checked1}"></span>
                    <div class="test">{{$t('message.wherecheck2')}}</div>
                </div>
                <div class="wap-link">
                    <span class="wap-linkbtn" @click="getSubmit">{{$t('message.submitbtn')}}</span>
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {jsReg,yooz_lang,web_inter} from '@/untils/common';
export default {
    name:'blog',
    inject:['reload'],
    data(){
        return{
            languageName:'',
            languageObj:{},
            checked:false,
            checked1:false,
            name:'',//用户名 	
            age:'',//年龄 	
            occupation:'',//职业 	
            industry:'',//行业 	
            website:'',//官网地址 	
            city:'',//城市 	
            country:'',//国家 	
            country_state:'',//状态 	
            cooperation_method:[],//合作方式 传入数组仅作示例 	
            enter_type:[],//入驻类型 传入数组仅作示例 	
            enter_type_str:'',//其他入驻类型 	
            afiliated_company_website:'',//附属公司官网 或有多少零售商店 	
            is_retail:'',//是否有零售店 	
            scale:'',//规模 	
            telno:'',//移动电话 	
            email:'',//邮箱 	
            contact:'',//联系方式 	
            contact_info:'',//联系信息 	
            is_intend_shop:'',//是否打算开店 	
            intend_shop_city:'',//专卖店城市 	
            working_reason:'',//合作原因 	
            recommending_officer:'',//推荐人员
            firstlist:[
                // {name:'分配',checked1:false},
                // {name:'零售',checked1:false},
                // {name:'网上销售',checked1:false},
                // {name:'其他',checked1:false},
            ],
            typelist:[
                // {name:'电子烟商店',checked:false},
                // {name:'CVS/加油站',checked:false},
                // {name:'烟酒店',checked:false},
                // {name:'电子商店',checked:false},
                // {name:'杂货店',checked:false},
                // {name:'大卖场/超市',checked:false},
                // {name:'餐厅',checked:false},
                // {name:'生活用品',checked:false},
                // {name:'其他，请注明',checked:false},
            ],
            shoplist:[
                // {name:'是的',checked:false},
                // {name:'没有',checked:false},
                // {name:'计划开张',checked:false},
            ],
            arealist:[
                // {name:'20套',checked:false},
                // {name:'200-500套',checked:false},
                // {name:'500-1000套',checked:false},
                // {name:'1000-4000套',checked:false},
            ],
            tzlist:[
                // {name:'是的',checked:false},
                // {name:'不',checked:false},
            ],
            languageList:web_inter.languageList,
            couponSelected:'',
        }
    },
    watch:{

    },
    computed:{

    },
    methods: {

        getArrayMore(){
            this.$http.api_server_cooperation().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            // *首选合作方式(可多选)
                            var firstlistobj = res.data.cooperation_method_list;
                            var firstlistarr = [];
                            for(let i in firstlistobj) {
                                firstlistarr.push ({id:i,name:firstlistobj[i],check:false});
                            }
                            this.firstlist = firstlistarr;

                            // *入驻类型(可多选)
                            var typelistobj = res.data.enter_type_list;
                            var typelistarr = [];
                            for(let i in typelistobj) {
                                typelistarr.push ({id:i,name:typelistobj[i],check:false});
                            }
                            this.typelist = typelistarr;

                            // *您拥有零售店吗？(单选)
                            var shoplistobj = res.data.is_retail_list;
                            var shoplistarr = [];
                            for(let i in shoplistobj) {
                                shoplistarr.push ({id:i,name:shoplistobj[i],check:false});
                            }
                            this.shoplist = shoplistarr;

                            // *规模(单选)
                            var arealistobj = res.data.scale_list;
                            var arealistarr = [];
                            for(let i in arealistobj) {
                                arealistarr.push ({id:i,name:arealistobj[i],check:false});
                            }
                            this.arealist = arealistarr;

                            // *您会考虑投资或开设 RELX 独家专卖店吗？
                            var tzlistobj = res.data.is_intend_shop_list;
                            var tzlistarr = [];
                            for(let i in tzlistobj) {
                                tzlistarr.push ({id:i,name:tzlistobj[i],check:false});
                            }
                            this.tzlist = tzlistarr;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 复选
        getCheck(type,item,index){
            if(type=='firstlist'){
                item.check = !item.check
            }else if(type=='typelist'){
                item.check = !item.check
            }
        },
        // 单选
        getRadio(val,type){
            if(type=='shoplist'){
                this.shoplist.map((v)=>{
                    v.check = false
                });
                val.check = true;
            }else if(type=='arealist'){
                this.arealist.map((v)=>{
                    v.check = false
                });
                val.check = true;
            }else if(type=='tzlist'){
                this.tzlist.map((v)=>{
                    v.check = false
                });
                val.check = true;
            }
        },
        // 提交合作数据
        getSubmit(){
            let firstarr = [];
            this.firstlist.map((v)=>{
                if(v.check){
                    firstarr.push(v.id);
                }
            });
            let typearr = [];
            this.typelist.map((v)=>{
                if(v.check){
                    typearr.push(v.id);
                }
            });
            let shoparr = [];
            this.shoplist.map((v)=>{
                if(v.check){
                    shoparr.push(v.id);
                }
            });
            let areaarr = [];
            this.arealist.map((v)=>{
                if(v.check){
                    areaarr.push(v.id);
                }
            });
            let tzarr = [];
            this.tzlist.map((v)=>{
                if(v.check){
                    tzarr.push(v.id);
                }
            });
            if(this.name==''){
                ElementUI.Message({
                    message:this.languageObj.allnametip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.age==''){
                ElementUI.Message({
                    message:this.languageObj.agetip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if (!jsReg.number.test(this.age)) {
                ElementUI.Message({
                    message:'请输入正确的年龄',
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false
            }else if(this.occupation==''){
                ElementUI.Message({
                    message:this.languageObj.occupationtip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.industry==''){
                ElementUI.Message({
                    message:this.languageObj.industrytip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.website==''){
                ElementUI.Message({
                    message:this.languageObj.webintertip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.city==''){
                ElementUI.Message({
                    message:this.languageObj.citytip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.country_state==''){
                ElementUI.Message({
                    message:this.languageObj.statustip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.country==''){
                ElementUI.Message({
                    message:this.languageObj.countrytitletip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(firstarr.length==0){
                ElementUI.Message({
                    message:this.languageObj.cooptypetip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(typearr.length==0){
                ElementUI.Message({
                    message:this.languageObj.jiontypetip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.afiliated_company_website==''){
                ElementUI.Message({
                    message:this.languageObj.companytip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(shoparr.join(',')==''){
                ElementUI.Message({
                    message:this.languageObj.shoptip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(areaarr.join(',')==''){
                ElementUI.Message({
                    message:this.languageObj.gmtip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(this.telno==''){
                ElementUI.Message({
                    message:this.languageObj.mobiletip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if (!jsReg.number.test(this.telno)) {
                ElementUI.Message({
                    message:'请输入正确的移动电话',
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false
            }else if(this.email==''){
                ElementUI.Message({
                    message:this.languageObj.eemailtip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if (!jsReg.email.test(this.email)) {
                ElementUI.Message({
                    message:this.languageObj.emailrighttip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false
            }else if(tzarr.join(',')==''){
                ElementUI.Message({
                    message:this.languageObj.oneshoptip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(!this.checked){
                ElementUI.Message({
                    message:this.languageObj.wherecheck1,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else if(!this.checked1){
                ElementUI.Message({
                    message:this.languageObj.wherecheck2,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }else{
                let params = {
                    name:this.name,//用户名 	
                    age:this.age,//年龄 	
                    occupation:this.occupation,//职业 	
                    industry:this.industry,//行业 	
                    website:this.website,//官网地址 	
                    city:this.city,//城市 	
                    country:this.country,//国家 	
                    country_state:this.country_state,//状态 	
                    cooperation_method:firstarr,//合作方式 传入数组仅作示例 	
                    enter_type:typearr,//入驻类型 传入数组仅作示例 	
                    enter_type_str:this.enter_type_str,//其他入驻类型 	
                    afiliated_company_website:this.afiliated_company_website,//附属公司官网 或有多少零售商店 	
                    is_retail:shoparr.join(','),//是否有零售店 	
                    scale:areaarr.join(','),//规模 	
                    telno:this.telno,//移动电话 	
                    email:this.email,//邮箱 	
                    contact:this.contact,//联系方式 	
                    contact_info:this.contact_info,//联系信息 	
                    is_intend_shop:tzarr.join(','),//是否打算开店 	
                    intend_shop_city:this.intend_shop_city,//专卖店城市 	
                    working_reason:this.working_reason,//合作原因 	
                    recommending_officer:this.recommending_officer,//推荐人员
                }
                // let params = {
                //     "name": "刘大大",
                //     "age": "12",
                //     "occupation": "销售",
                //     "industry": "电子烟销售",
                //     "website": "www.shenmeyemeiyou.com",
                //     "city": "北京",
                //     "country": "中国",
                //     "country_state": "状态是啥",
                //     "cooperation_method": ['1'],
                //     // "cooperation_method[1]": '2',
                //     "enter_type":['1'],
                //     // "enter_type[1]":'2',
                //     "is_retail": "1",
                //     "is_intend_shop": "1",
                //     "scale": "1",
                //     "enter_type_str": "入驻类型",
                //     "afiliated_company_website": "20家",
                //     "telno": "18810111111",
                //     "email": "12222@163.com",
                //     "contact": "12222222222",
                //     "contact_info": "2222222222",
                //     "intend_shop_city": "天津",
                //     "working_reason": "因为",
                //     "recommending_officer": "花丽丽"
                // }
                this.$http.api_server_newcooperation(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                ElementUI.Message({
                                    message:res.message,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                })
            }
            
        }
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "allnametip":"请输入您的全名",
                "agetip":"请输入您的年龄",
                "occupationtip":"请输入您的职业",
                "industrytip":"请输入您的行业",
                "webintertip":"请输入您的公司网站",
                "citytip":"请输入您的城市",
                "statustip":"请输入您的状态",
                "countrytip":"请输入您的国家",
                "countrytitletip":"请输入您的国籍",
                "cooptypetip":"请选择首选合作方式",
                "jiontypetip":"请选择入驻类型",
                "companytip":"请输入您有几家零售商店/附属公司链接",
                "shoptip":"请选择您拥有零售店吗",
                "gmtip":"请选择规模",
                "mobiletip":"请输入移动电话",
                "eemailtip":"请输入联系电子邮箱",
                "oneshoptip":"请选择您会考虑投资或开设 RELX 独家专卖店吗",
                "checkinfotip":"请您同意订阅以接收新闻通讯/营销信息",
                "checkwheretip":"请您阅读并同意RELX的隐私政策和条款条件",
                "wherecheck1":"订阅以接收新闻通讯/营销信息。",
                "wherecheck2":"我们非常重视您的隐私，只会使用您的个人信息来提供您要求的产品和服务。要继续，请阅读并同意37 vape 的隐私政策和条款条件。",
                "emailrighttip":"请输入正确的邮箱",
                "phonerighttip":"请输入正确的手机号",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "allnametip":"enter your full name",
                "agetip":"enter your age",
                "occupationtip":"enter your occupation",
                "industrytip":"enter your industry",
                "webintertip":"enter your company website",
                "citytip":"enter your city",
                "statustip":"enter your status",
                "countrytip":"enter your country",
                "countrytitletip":"enter your nationality",
                "cooptypetip":"select your preferred cooperation method",
                "jiontypetip":"select the type of occupancy",
                "companytip":"enter how many retail stores/affiliate links you have",
                "shoptip":"select if you own a retail store",
                "gmtip":"select a size",
                "mobiletip":"enter mobile phone",
                "eemailtip":"enter contact email",
                "oneshoptip":"select if you would consider investing or opening a 37 Vape exclusive store",
                "checkinfotip":"Please agree to subscribe to receive newsletters/marketing information",
                "checkwheretip":"Please read and agree to 37 Vape's Privacy Policy and Terms and Conditions",
                "wherecheck1":"Subscribe to receive newsletters/marketing information.",
                "wherecheck2":"We take your privacy very seriously and will only use your personal information to provide the products and services you request. To continue, please read and agree to 37 Vape's Privacy Policy and Terms and Conditions.",
                "emailrighttip":"Please enter the correct email address",
                "phonerighttip":"Please enter the correct mobile number",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "allnametip":"Inserisci il tuo nome e cognome",
                "agetip":"inserisci la tua età",
                "occupationtip":"Inserisci la tua occupazione",
                "industrytip":"Inserisci il tuo settore",
                "webintertip":"Inserisci il sito web della tua azienda",
                "citytip":"inserisci la tua città",
                "statustip":"inserisci il tuo stato",
                "countrytip":"inserisci il tuo paese",
                "countrytitletip":"Inserisci la tua nazionalità",
                "cooptypetip":"seleziona il metodo di cooperazione preferito",
                "jiontypetip":"Inserisci la tua occupazione",
                "companytip":"Numero punti vendita/affiliare",
                "shoptip":"Seleziona se possiedi un negozio al dettaglio",
                "gmtip":"seleziona una metodo di collaborazione",
                "mobiletip":"inserisci il cellulare",
                "eemailtip":"inserisci l'e-mail di contatto",
                "oneshoptip":"Seleziona se intendi investire o aprire un negozio esclusivo 37 Vape",
                "checkinfotip":"iscriviti per ricevere newsletter/informazioni di marketing",
                "checkwheretip":"Si prega di leggere e accettare l'Informativa sulla privacy e i Termini e condizioni di 37 Vape",
                "wherecheck1":"Iscriviti per ricevere newsletter/informazioni marketing.",
                "wherecheck2":"Prendiamo molto sul serio la privacy dei nostri clienti e partners e utilizzeremo le informazioni personali solo per fornire i prodotti e i servizi richiesti. Per continuare, leggi e accetta la Privacy Policy e i Termini e Condizioni di 37 Vape.",
                "emailrighttip":"Inserisci l'indirizzo email corretto",
                "phonerighttip":"Inserisci il numero di cellulare corretto",
            }
        }
        // 合作机会的选择数据
        this.getArrayMore();
    },
    mounted(){
        let that = this;
        that.$nextTick(()=>{
            that.couponSelected = this.languageList[0].mobile_area_code
        });
    }
}
</script>
<style lang="scss" scoped>
.clear{
    clear:both;
}
.navtab{
    padding:20px 10px 10px;
    
}
.banner{
    display:flex;
    margin-top: 10px;
    height:390px;
    width: 100%;
    background: url(~@/assets/images/cooperation_banner.png) no-repeat left top;
    background-size: 100% 100%;
    -webkit-box-orient: vertical;
    .centrr{
        .trg{
            width: 100%;
            padding:120px 20px 0;
            .title{
                font-weight: bold;
                font-size:50px;
                width: 100%;
            }
            .txt{
                padding:20px 0;
                width: 100%;
            }
            .tip{
                color: #1f73b7;
                width: 100%;
            }
        }
    }
    
}
.pc-from-wrap{
    margin: 0;
    padding:20px 200px;
    clear:both;
    .pc-title{
        color: #616166;
        padding:20px 0;
    }
    .pc-box-txt{
        display: block;
        width: 100%;
        padding:15px 0;
        clear:both;
        .icon_checkbox{
            float: left;
            display: block;
            width:20px;
            height:20px;
            background:url(~@/assets/images/icon_check.png) no-repeat left top;
            background-size: 100% 100%;
            margin-top:3px;
            margin-right:8px;
            cursor: pointer;
            border-radius: 3px;
        }
        .checked{
            background:url(~@/assets/images/icon_checked.png) no-repeat left top;
            background-size: 100% 100%;
        }
        .test{
            float: left;
            display: block;
            width: 70%;
        }
    }
    .pc-box{
        margin-bottom: 20px;
        cursor: pointer;
        .icon_checkbox{
            display: inline-block;
            width:20px;
            height:20px;
            background:url(~@/assets/images/icon_check.png) no-repeat left top;
            background-size: 100% 100%;
            margin-top:-3px;
            margin-right:8px;
            cursor: pointer;
            border-radius: 3px;
            vertical-align: middle;
        }
        .checked{
            background:url(~@/assets/images/icon_checked.png) no-repeat left top;
            background-size: 100% 100%;
        }
        .icon_radio{
            display: inline-block;
            width:20px;
            height:20px;
            background:url(~@/assets/images/icon-radio.png) no-repeat left top;
            background-size: 100% 100%;
            margin-top:-3px;
            margin-right:8px;
            cursor: pointer;
            border-radius: 3px;
            vertical-align: middle;
        }
        .radioed{
            background:url(~@/assets/images/icon-radioed.png) no-repeat left top;
            background-size: 100% 100%;
        }
        
        
        .el-row{
            .el-col{
                cursor: pointer;
                padding:10px 0;
            }
        }
        span{color: #1F73B7;}
        /deep/.el-checkbox{
            color: #2E2E2E;
        }
        /deep/.el-checkbox__input{
            vertical-align: top;
            padding-top: 3px;
        }
        /deep/.el-checkbox__label{
            color: #2E2E2E;
            width: 93%;
            white-space: pre-wrap !important;
            word-break: break-all;
            word-wrap: break-word;
        }
        /deep/.el-checkbox__input.is-checked+.el-checkbox__label{
            color: #2E2E2E;
            width: 93%;
            white-space: pre-wrap !important;
            word-break: break-all;
            word-wrap: break-word;
            font-size:16px;
        }
        /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
            background-color:#1F73B7;
            border-color:#1F73B7;
        }
        /deep/.el-checkbox__inner{
            width: 20px;
            height:20px;
            vertical-align: top;
        }
        /deep/.el-checkbox__inner::after {
            left: 7px;
            top: 3px;
            height:9px;
        }
        /deep/.el-radio__inner {
            width: 20px;
            height: 20px;
        }
        /deep/.el-radio__input.is-checked .el-radio__inner{
            background-color:#1F73B7;
            border-color:#1F73B7;
        }
        /deep/.el-radio__input.is-checked+.el-radio__label{
            color: #2E2E2E;
            font-size:16px;
        }
    }
    .pc-login-group{
        border: 1px solid #dde0ed;
        border-radius:4px;
        padding:10px 0;
        margin-bottom: 20px;
        select{
            width: 20%;
        }
        select,input{
            outline:none;
            border:none;
            padding:0 10px;
            color: #95959E;
            background: transparent;
        }
        
        // WebKit 谷歌
        input::-webkit-input-placeholder {
            color: #95959E;
        }
        // Mozilla Firefox 4 - 18 适配火狐
        input:-moz-placeholder { 
            color: #95959E;
        }
        // Mozilla Firefox 19+ 适配火狐
        input::-moz-placeholder {
            color: #95959E;
        }
        // IE 10+  
        input:-ms-input-placeholder { 
            color: #95959E
        }
        .pc-password{
            width: 78%;
            margin-left: 2%;
            border-left:1px solid #dde0ed;
            padding:0 10px 0 20px;
        }
    }
    .pc-input-two{
        display: flex;
        -webkit-box-orient: horizontal;
        height: 58px;
        width: 100%;
        margin-bottom: 20px;
        /deep/.el-select{
            width: 48%;
        }
        .pc-t{width:4%}
        input{
            height: 100%;
            width: 48%;
            border:1px solid #dde0ed;
            outline: none;
            padding:0 20px;
            border-radius:5px;
            background: transparent;
        }
        
        // WebKit 谷歌
        input::-webkit-input-placeholder {
            color: #95959E;
        }
        // Mozilla Firefox 4 - 18 适配火狐
        input:-moz-placeholder { 
            color: #95959E;
        }
        // Mozilla Firefox 19+ 适配火狐
        input::-moz-placeholder {
            color: #95959E;
        }
        // IE 10+  
        input:-ms-input-placeholder { 
            color: #95959E
        }
        .select{
            height: 100%;
            width: 48%;
            border:1px solid #dde0ed;
            outline: none;
            padding:0 20px;
            border-radius:5px;
            position: relative;
            select{
                height: 100%;
                width: 100%;
                outline: none;
                border: none;
                background: transparent;
                appearance:none;   
                -moz-appearance:none;   
                -webkit-appearance:none; 
                color: #95959E;
            }
            // WebKit 谷歌
            select::-webkit-input-placeholder {
                color: #95959E;
            }
            // Mozilla Firefox 4 - 18 适配火狐
            select:-moz-placeholder { 
                color: #95959E;
            }
            // Mozilla Firefox 19+ 适配火狐
            select::-moz-placeholder {
                color: #95959E;
            }
            // IE 10+  
            select:-ms-input-placeholder { 
                color: #95959E
            }
            .el-icon-arrow-down{
                position: absolute;
                right: 20px;
                top:20px;
            }
        }
    }
    .pc-input{
        height: 58px;
        width: 100%;
        border:1px solid #dde0ed;
        margin-bottom: 20px;
        border-radius:5px;
        input{
            height: 100%;
            width: 100%;
            border: none;
            outline: none;
            padding:0 20px;
            background: transparent;
        }
        
        // WebKit 谷歌
        input::-webkit-input-placeholder {
            color: #95959E;
        }
        // Mozilla Firefox 4 - 18 适配火狐
        input:-moz-placeholder { 
            color: #95959E;
        }
        // Mozilla Firefox 19+ 适配火狐
        input::-moz-placeholder {
            color: #95959E;
        }
        // IE 10+  
        input:-ms-input-placeholder { 
            color: #95959E
        }
    }
    .pc-link{
        height: 58px;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        .pc-linkbtn{
            display: inline-block;
            padding:10px 40px;
            border-radius:4px;
            background: #222;
            color: #fff;
            cursor: pointer;
        }
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-from-box{display: none;}
        
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-from-box{display: none;}
        .pc-from-wrap{
            padding:20px 50px;
        }
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-from-box{display: none;}
        .pc-from-wrap{
            padding:20px 10px;
        }
       
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-from-box{display: none;}
        .wap-from-box{
            padding: 0 10px 30px;
            .wap-from-wrap{
                .wap-input{
                    width:100%;
                    border:1px solid #DDE0ED;
                    border-radius:4px;
                    padding:15px 10px;
                    margin-top: 10px;
                    input{
                        width:100%;
                        border: none;
                        outline: none;
                        background: transparent;
                        padding:0;
                        
                    }
                }
                .wap-box-txt{
                    display: flex;
                    width: 100%;
                    padding:15px 0 0;
                    clear:both;
                    .icon_checkbox{
                        float: left;
                        display: block;
                        width:20px;
                        height:20px;
                        background:url(~@/assets/images/icon_check.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-top:3px;
                        margin-right:8px;
                        cursor: pointer;
                        border-radius: 3px;
                    }
                    .checked{
                        background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .test{
                        float: left;
                        display: block;
                        width: 70%;
                    }
                }
            }
            .wap-label-title{
                color: #95959e;
                padding:10px 0;
            }
            .wap-label-list{
                color: #2E2E2E;
                .el-row{
                    .el-col{
                        padding:5px 0;
                    }
                }
                .icon_checkbox{
                    display: inline-block;
                    width:20px;
                    height:20px;
                    background:url(~@/assets/images/icon_check.png) no-repeat left top;
                    background-size: 100% 100%;
                    margin-top:-3px;
                    margin-right:8px;
                    cursor: pointer;
                    border-radius: 3px;
                    vertical-align: middle;
                }
                .checked{
                    background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .icon_radio{
                    display: inline-block;
                    width:20px;
                    height:20px;
                    background:url(~@/assets/images/icon-radio.png) no-repeat left top;
                    background-size: 100% 100%;
                    margin-top:-3px;
                    margin-right:8px;
                    cursor: pointer;
                    border-radius: 3px;
                    vertical-align: middle;
                }
                .radioed{
                    background:url(~@/assets/images/icon-radioed.png) no-repeat left top;
                    background-size: 100% 100%;
                }
            }
            .wap-label-txt{
                width: 100%;
                padding:10px 0 0 0;
                span{color: #1F73B7;}
                /deep/.el-checkbox{
                    color: #2E2E2E;
                }
                /deep/.el-checkbox__input{
                    vertical-align: top;
                    padding-top: 3px;
                }
                /deep/.el-checkbox__label{
                    color: #2E2E2E;
                    width: 100%;
                    padding-right: 15px;
                    white-space: pre-wrap !important;
                    word-break: break-all;
                    word-wrap: break-word;
                }
                /deep/.el-checkbox__input.is-checked+.el-checkbox__label{
                    color: #2E2E2E;
                    width: 93%;
                    white-space: pre-wrap !important;
                    word-break: break-all;
                    word-wrap: break-word;
                }
                /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
                    background-color:#1F73B7;
                    border-color: 1px solid #1F73B7;
                }
            }   
            .wap-link{
                height: 40px;
                width: 100%;
                text-align: center;
                margin-top: 20px;
                .wap-linkbtn{
                    display: inline-block;
                    width: 100%;
                    padding:10px 40px;
                    border-radius:4px;
                    background: #222;
                    color: #fff;
                    
                    cursor: pointer;
                }
            }
            .wap-login-group{
                border: 1px solid #DDE0ED;
                border-radius:4px;
                padding:10px 0;
                margin-top: 20px;
                select,input{
                    outline:none;
                    border:none;
                    padding:0 10px;
                    background: transparent;
                }
                .password{
                    width: 100%;
                }
            }
        }
        .banner{
            display:flex;
            margin-top: 10px;
            height:150px;
            width: 100%;
            background: url(~@/assets/images/cooperation_banner.png) no-repeat left top;
            background-size: 100% 100%;
            -webkit-box-orient: vertical;
            .centrr{
                padding:0;
                .trg{
                    width: 100%;
                    padding:20px 10px 0;
                    .title{
                        font-weight: bold;
                        font-size:20px;
                        width: 100%;
                    }
                    .txt{
                        padding:5px 0;
                        width: 100%;
                        font-size:16px;
                    }
                    .tip{
                        color: #1f73b7;
                        width: 100%;
                        font-size:16px;
                    }
                }
            }
            
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        padding-bottom: 30px;
        .pc-from-box{display: none;}
        .wap-from-box{
            padding: 0 10px 30px;
            .wap-input{
                width:100%;
                border:1px solid #DDE0ED;
                border-radius:4px;
                padding:15px 10px;
                margin-top: 10px;
                input{
                    width:100%;
                    border: none;
                    outline: none;
                    background: transparent;
                    padding:0;
                    
                }
            }
            .wap-box-txt{
                display: flex;
                width: 100%;
                padding:15px 0 0;
                clear:both;
                .icon_checkbox{
                    float: left;
                    display: block;
                    width:20px;
                    height:20px;
                    background:url(~@/assets/images/icon_check.png) no-repeat left top;
                    background-size: 100% 100%;
                    margin-top:3px;
                    margin-right:8px;
                    cursor: pointer;
                    border-radius: 3px;
                }
                .checked{
                    background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .test{
                    float: left;
                    display: block;
                    width: 70%;
                }
            }
        }
        .wap-label-title{
            color: #95959e;
            padding:10px 0;
        }
        .wap-label-list{
            color: #2E2E2E;
            .el-row{
                .el-col{
                    padding:5px 0;
                }
            }
            .icon_checkbox{
                display: inline-block;
                width:20px;
                height:20px;
                background:url(~@/assets/images/icon_check.png) no-repeat left top;
                background-size: 100% 100%;
                margin-top:-3px;
                margin-right:8px;
                cursor: pointer;
                border-radius: 3px;
                vertical-align: middle;
            }
            .checked{
                background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .icon_radio{
                display: inline-block;
                width:20px;
                height:20px;
                background:url(~@/assets/images/icon-radio.png) no-repeat left top;
                background-size: 100% 100%;
                margin-top:-3px;
                margin-right:8px;
                cursor: pointer;
                border-radius: 3px;
                vertical-align: middle;
            }
            .radioed{
                background:url(~@/assets/images/icon-radioed.png) no-repeat left top;
                background-size: 100% 100%;
            }
        }
        .wap-label-txt{
            width: 100%;
            padding:10px 0 0 0;
            span{color: #1F73B7;}
            /deep/.el-checkbox{
                color: #2E2E2E;
            }
            /deep/.el-checkbox__input{
                vertical-align: top;
                padding-top: 3px;
            }
            /deep/.el-checkbox__label{
                color: #2E2E2E;
                width: 100%;
                padding-right: 15px;
                white-space: pre-wrap !important;
                word-break: break-all;
                word-wrap: break-word;
            }
            /deep/.el-checkbox__input.is-checked+.el-checkbox__label{
                color: #2E2E2E;
                width: 93%;
                white-space: pre-wrap !important;
                word-break: break-all;
                word-wrap: break-word;
            }
            /deep/.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
                background-color:#1F73B7;
                border-color: 1px solid #1F73B7;
            }
        }   
        
        .wap-link{
            height: 40px;
            width: 100%;
            text-align: center;
            margin-top: 20px;
            .wap-linkbtn{
                display: inline-block;
                width: 100%;
                padding:10px 40px;
                border-radius:4px;
                background: #222;
                color: #fff;
                
                cursor: pointer;
            }
        }
        .wap-login-group{
            border: 1px solid #DDE0ED;
            border-radius:4px;
            padding:10px 0;
            margin-top: 20px;
            select,input{
                outline:none;
                border:none;
                padding:0 10px;
                background: transparent;
            }
            .password{
                width: 100%;
            }
        }
        .banner{
            display:flex;
            margin-top: 10px;
            height:150px;
            width: 100%;
            background: url(~@/assets/images/cooperation_banner.png) no-repeat left top;
            background-size: 100% 100%;
            -webkit-box-orient: vertical;
            .centrr{
                padding:0;
                .trg{
                    width: 100%;
                    padding:20px 10px 0;
                    .title{
                        font-weight: bold;
                        font-size:20px;
                        width: 100%;
                    }
                    .txt{
                        padding:5px 0;
                        width: 100%;
                        font-size:16px;
                    }
                    .tip{
                        color: #1f73b7;
                        width: 100%;
                        font-size:16px;
                    }
                }
            }
            
        }
       
    }
}
</style>